var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["callback-block", "callback-block--" + _vm.theme],
      attrs: { id: _vm.idFormblock },
    },
    [
      _c("content-wrapper-block", { staticClass: "callback-block__row" }, [
        _c("div", { staticClass: "callback-block__left" }, [
          _c("div", { staticClass: "callback-block__title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("div", { staticClass: "callback-block__subtitle" }, [
            _vm._v(" " + _vm._s(_vm.subtitle) + " "),
          ]),
          _c(
            "form",
            {
              ref: "callback-form",
              staticClass: "callback-block__form callback-form",
              attrs: { id: _vm.id, method: "POST" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "callback-form__row" }, [
                _c(
                  "div",
                  { staticClass: "callback-form__col col-3" },
                  [
                    _c("ui-input", {
                      ref: "form-name",
                      attrs: {
                        id: _vm.id + "-name",
                        hint: "Введите корректные данные",
                        dark: _vm.theme === "black" ? true : false,
                        "placeholder-value": "Имя",
                        required: "",
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "callback-form__col col-3" },
                  [
                    _c("ui-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "+7 (###) ###-##-##",
                          expression: "'+7 (###) ###-##-##'",
                        },
                      ],
                      ref: "form-phone",
                      attrs: {
                        id: _vm.id + "-phone",
                        type: "tel",
                        dark: _vm.theme === "black" ? true : false,
                        hint: "Введите корректный номер телефона",
                        "placeholder-value": "телефон",
                        required: "",
                      },
                      model: {
                        value: _vm.phone,
                        callback: function ($$v) {
                          _vm.phone = $$v
                        },
                        expression: "phone",
                      },
                    }),
                    !_vm.isPhoneValid
                      ? _c("div", { staticClass: "form-phone_valid" }, [
                          _vm._v(" Введите корректный номер телефона "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hidden,
                      expression: "hidden",
                    },
                  ],
                  attrs: { name: "ch" },
                  domProps: { value: _vm.hidden },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.hidden = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "callback-form__col" },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          type: "submit",
                          outline: _vm.theme === "black" ? false : true,
                          loading: _vm.loading,
                        },
                      },
                      [_vm._v(" Отправить ")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.enableAgreement
                ? _c("div", { staticClass: "callback-form__row" }, [
                    _c(
                      "div",
                      { staticClass: "callback-form__col" },
                      [
                        _c("ui-input", {
                          ref: "form-agreement",
                          staticClass: "row",
                          attrs: {
                            id: _vm.id + "-agreement",
                            type: "checkbox",
                            dark: _vm.theme === "black" ? true : false,
                            required: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "Нажимая кнопку «Отправить», я соглашаюсь с политикой обработки "
                                        ),
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: { name: "UserAgreementPage" },
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v("персональных данных")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3188345207
                          ),
                          model: {
                            value: _vm.checkbox,
                            callback: function ($$v) {
                              _vm.checkbox = $$v
                            },
                            expression: "checkbox",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _c("div", {
          staticClass: "callback-block__right",
          style: _vm.backgroundStyle,
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }