<template>
  <div
    :id="idFormblock"
    :class="['callback-block', 'callback-block--' + theme]"
  >
    <content-wrapper-block class="callback-block__row">
      <div class="callback-block__left">
        <div class="callback-block__title">
          {{ title }}
        </div>
        <div class="callback-block__subtitle">
          {{ subtitle }}
        </div>
        <form
          :id="id"
          ref="callback-form"
          method="POST"
          class="callback-block__form callback-form"
          @submit.prevent="submit"
        >
          <div class="callback-form__row">
            <div class="callback-form__col col-3">
              <ui-input
                :id="id + '-name'"
                ref="form-name"
                v-model="name"
                hint="Введите корректные данные"
                :dark="theme === 'black' ? true : false"
                placeholder-value="Имя"
                required
              />
            </div>
            <div class="callback-form__col col-3">
              <ui-input
                :id="id + '-phone'"
                ref="form-phone"
                v-model="phone"
                v-mask="'+7 (###) ###-##-##'"
                type="tel"
                :dark="theme === 'black' ? true : false"
                hint="Введите корректный номер телефона"
                placeholder-value="телефон"
                required
              />
              <div v-if="!isPhoneValid" class="form-phone_valid">
                Введите корректный номер телефона
              </div>
            </div>
            <input v-model="hidden" name="ch" />
            <div class="callback-form__col">
              <ui-button
                type="submit"
                :outline="theme === 'black' ? false : true"
                :loading="loading"
              >
                Отправить
              </ui-button>
            </div>
          </div>
          <div v-if="enableAgreement" class="callback-form__row">
            <div class="callback-form__col">
              <ui-input
                :id="id + '-agreement'"
                ref="form-agreement"
                v-model="checkbox"
                class="row"
                type="checkbox"
                :dark="theme === 'black' ? true : false"
                required
              >
                <template #label>
                  <span
                    >Нажимая кнопку «Отправить», я соглашаюсь с политикой
                    обработки
                    <router-link
                      :to="{ name: 'UserAgreementPage' }"
                      target="_blank"
                      >персональных данных</router-link
                    >
                  </span>
                </template>
              </ui-input>
            </div>
          </div>
        </form>
      </div>
      <div class="callback-block__right" :style="backgroundStyle" />
    </content-wrapper-block>
  </div>
</template>

<script>
import { helpers } from "@osnova-adv/ms3-blocks";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import UiInput from "@/components/ui/UIInput";
import UiButton from "@/components/ui/UIButton";

export default {
  name: "CallbackBlock",
  components: {
    ContentWrapperBlock,
    UiInput,
    UiButton,
  },
  props: {
    idFormblock: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "ui-input",
    },
    title: {
      type: String,
      default: "Остались вопросы?",
    },
    subtitle: {
      type: String,
      default:
        "Оставьте свои контакты, и наш менеджер подробно проконсультирует вас",
    },
    theme: {
      type: String,
      default: "white",
    },
    image: {
      type: String,
      default: "",
    },
    enableAgreement: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    name: "",
    phone: "",
    hidden: "",
    checkbox: true,
    loading: false,
  }),
  computed: {
    backgroundStyle() {
      return {
        background: `#FFF url(${this.image}) no-repeat 50% 50%`,
        backgroundSize: "cover",
        height: "333px",
      };
    },
    isPhoneValid() {
      return this.phone.length >= 18 || this.phone.length < 1;
    },
  },
  methods: {
    send() {
      window.Comagic.addOfflineRequest(
        {
          name: "Имя: " + this.name + " | " + "Форма: " + this.title,
          phone: this.phone,
          message:
            "Описание: " +
            this.title +
            " | " +
            "URL: " +
            document.URL +
            " | " +
            "ID: " +
            this.idFormblock,
        },
        ({ response }) => {
          const result = JSON.parse(response);
          if (result.success) {
            window.popupWithChats.showResponse({ success: true });
            dataLayer.push({
              event: "form-send",
              "event-category": "proposition",
              "event-action": "send",
            });
          } else {
            window.popupWithChats.showResponse();
          }
          this.reset();
        }
      );
    },
    submit() {
      if (this.hidden || this.phone.length !== 18 || !this.name.trim()) {
        return;
      }
      this.$refs["form-name"].$refs.VueInputUi.classList.remove("attempt");
      this.$refs["form-phone"].$refs.VueInputUi.classList.remove("attempt");
      this.showLoader();
      this.send();
    },
    reset() {
      this.hideLoader();
      this.clear();
    },
    clear() {
      this.name = "";
      this.phone = "";
    },
    showLoader() {
      this.loading = true;
    },
    hideLoader() {
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.callback-block {
  padding: 0;

  @media screen and (min-width: $screen-sm) {
    padding: 70px 0;
  }

  &--white {
    background: #2e2a2b;

    .callback-block__left {
      background: #fff;
    }
  }

  &--black {
    background: rgba(0, 0, 0, 0.95);

    .callback-block__left {
      background: #000;
      border: 0;
      color: #ffffff;
      padding-top: 30px;

      @media screen and (min-width: $screen-sm) {
        border: 1px solid rgba(255, 255, 255, 0.75);
        padding-top: 0;
      }
    }
  }

  &__title {
    font-family: Bebas Neue, serif;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 15px;
    letter-spacing: 0.05em;

    margin-top: 0;
    font-size: 24px;
    line-height: 105%;

    @media screen and (min-width: $screen-sm) {
      margin-top: 30px;
      font-size: 36px;
      line-height: 130%;
    }
  }
  &__subtitle {
    font-size: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  &__col {
    display: none;
  }
  &__row {
    display: flex;
    align-items: center;
    padding: 0;

    .row {
      a {
        color: rgba(24, 24, 27, 0.5);
        &:hover {
          text-decoration: none;
        }
      }
    }

    @media screen and (min-width: $screen-sm) {
      padding: 0 15px;
    }
  }
  &__left {
    width: 100%;
    height: 333px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;

    @media screen and (min-width: $screen-sm) {
      padding: 40px;
    }
    @media screen and (min-width: $screen-md) {
      width: calc(100% - 370px);
      padding: 0 25px 0 25px;
    }
    @media screen and (min-width: $screen-lg) {
      padding: 0 75px 0 45px;
    }
  }
  &__right {
    width: 370px;
    display: none;

    @media screen and (min-width: $screen-md) {
      display: inline;
    }
  }
}
.callback-form {
  padding: 27px 0;

  @media screen and (min-width: $screen-mini) {
    padding: 51px 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 22px;
    flex-wrap: wrap;

    &:last-child {
      padding-bottom: 0;
    }
  }
  .col-3 {
    position: relative;
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 10px;

    @media screen and (min-width: $screen-sm) {
      width: 33%;
      margin-right: 0;
      margin-bottom: 0;
    }
    @media screen and (min-width: $screen-md) {
      width: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  .form-phone_valid {
    position: absolute;
    bottom: -7px;
    font-family: Rubik;
    font-size: 11px;
    text-transform: none;
    color: #ed0000;
  }

  input {
    &[name="ch"] {
      display: none;
      visibility: hidden;
    }
  }
}
</style>
